import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Alert} from "reactstrap"
import styled from "styled-components"
import Select from "react-select"
import {Link} from "gatsby"
import {colors, fonts} from "../../theme.js"

const wordbooks = [
  {
    value: "https://amzn.to/2Oh0S46",
    label: "Word Histories and Mysteries: From Abracadabra To Zeus",
  },
  {
    value: "https://amzn.to/30EtYgv",
    label: "The Facts on File Encyclopedia of Word and Phrase Origins",
  },
  {
    value: "https://amzn.to/30A83ad",
    label: "Barnhart Concise Dictionary of Etymology",
  },
]

class WordBooks extends Component {
  state = {
    selectedOption: null,
  }
  handleChange = selectedOption => {
    this.setState({selectedOption})
    window.open(selectedOption.value, `_blank`, `noopener`)
    console.log(`Option selected:`, selectedOption)
  }

  render() {
    const {selectedOption} = this.state

    return (
      <Select
        placeholder="Select a book..."
        onChange={this.handleChange}
        value={selectedOption}
        options={wordbooks}
        isOptionDisabled={option => option.disabled === "yes"}>
        >
      </Select>
    )
  }
}

function BuyButton(props) {
  return (
    <div style={{width: `100%`, textAlign: `center`}}>
      <BuyNow href={props.url} target="_blank" rel="noopener noreferrer">
        {props.text}
      </BuyNow>
    </div>
  )
}

const AboutPage = () => (
  <Layout>
    <SEO
      title="The Best Books On Word, Phrase And Custom Origins"
      description="Here you'll find the best books for learning about word (etymology), phrases and cultural custom origins."
      image="https://sparkfiles.net/og-image.jpg"
      url="https://sparkfiles.net/word-origin-books/"
    />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          <PurpleSection>
            <AboutContent>
              <h1 className="text-center" style={{fontFamily: `Jost`}}>
                The Best <span style={{color: colors.meatRare}}>Books</span> On
                Word, Phrase And Custom Origins
              </h1>
            </AboutContent>
          </PurpleSection>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <p>
              <b>
                Are you looking for the best books and resources for word
                origins (etymology), expressions and cultural customs?
              </b>
            </p>
            <p>
              On this page you'll find our list of recommended books. There are
              so many excellent resources out there but we've personally
              selected a few for you.
            </p>
            <p>
              Over time we'll expand this list to keep it up to date in case new
              books become available.
            </p>
            <Disclaimer>
              <b>
                <u>Full transparency</u>:
              </b>{" "}
              When we share a link to a book or resource, you should always
              assume that it's an affiliate link (usually on Amazon). This means we take a (very)
              small commission on sales. It doesn't change the price for you but
              we thought we'd let you know anyway.
            </Disclaimer>
            <ShortLine />
            <SubHeading>Word origin books</SubHeading>
            <p>
              <b>Here are some quick links to our favorite resources (on Amazon):</b>
            </p>
            <WordBooks />
            <ShortLine />
            <p>
		    Also check out our <Link to="/blog/">Spark Files</Link> for loads
              of interesting facts on word, phrase and custom origins.
            </p>
            <p>
              If there's anything we absolutely should add to our list, please{" "}
	      <Link to="/contact-me/">contact us</Link> and let us know.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const HeroImage = styled.div`
  position: relative;
`
const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
  padding-bottom: ;
`
const ColorSection = styled.div`
  background-image: linear-gradient(160deg, #d25337 0%, #181313 95%);
  color: #fff;
  padding-top: 5px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  p {
    color: #fff !important;
  }
  a {
    color: #5b9af5;
    &:hover {
      color: #34578b !important;
    }
  }
`
const PurpleSection = styled.div`
  background-image: linear-gradient(316deg, ${colors.meatChar} 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
const SubHeading = styled.h2`
  margin-top: 1em;
  color: ${colors.meatChar};
  text-align: center;
`
const Disclaimer = styled(Alert)`
  border: none !important;
  color: #fff !important;
  background-color: ${colors.meatChar} !important;
  font-style: italic;
`
const BuyNow = styled.a`
  font-size: 100%;
  font-weight: 700;
  cursor: pointer;
  display: block;
  max-width: 300px;
  margin-top: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding: 10px 5px 10px 5px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 6px;
  background: #000;
  background-image: linear-gradient(to right, #161616, ${colors.meatFire});
  box-shadow: 0 1px 3px #000;
  &:hover {
    color: #fff !important;
    box-shadow: none;
  }
`
